// Override Bootstrap default variables here
// Do not edit any of the files in /vendor/bootstrap/scss/!

// Color Variables
// Bootstrap Color Overrides

$white: #fff !default;
$gray-2: #fbfbfb !default;
$gray-3: #f5f5f5 !default;
$gray-4: #f0f0f0 !default;
$gray-5: #e8e8e8 !default;
$gray-6: #e1e1e1 !default;
$gray-7: #bfbfbf !default;
$gray-8: #a7a7a7 !default;
$gray-9: #888888 !default;
$gray-10: #757575 !default;
$gray-11: #4d4d4d !default;
$gray-12: #1c1c1c !default;
$black: #000 !default;

$blue: #4e73df !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff001e !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #20c9a6 !default;
$cyan: #006064 !default;

// scss-docs-start theme-colors-map
$primary: #ff7700;
$secondary: #205375;
$skyblue: #53d8fb;
$danger: #d90000;
$warning: #ffc300;
$info: #6f42c1;
$success: #519259;
$light: #e1e1e1;
$dark: #1c1c1c;

$dark-50: #f5f5f5;
$dark-100: #e9e9e9;
$dark-200: #d9d9d9;
$dark-300: #c4c4c4;
$dark-400: #9e9e9e;
$dark-500: #7c7c7c;
$dark-600: #565656;
$dark-700: #444444;
$dark-800: #272727;

// Custom Colors
$input-border-color: #bfbfbf;
$input-border-hover-color: #757575;
$input-label-color: #3f3f42;
$input-placeholder-color: #8c87a6;
$input-group-addon-bg: #e5eaf0;
$input-disabled-bg: #e5eaf0;
$form-floating-label-transform: scale(1) translateY(0rem) translateX(0rem);
$form-floating-input-padding-t: 1.425rem;
$form-floating-input-padding-b: 0.325rem;
$form-check-radio-checked-disable-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#7c7c7c'/></svg>");
$body-bg-color: $gray-2 !default;

// Brand Colors
$brand-google: #ea4335 !default;
$brand-facebook: #3b5998 !default;
$brand-twitter: #1da1f2 !default;
$brand-linkedin: #0a66c2 !default;
$brand-instagram: #c32aa3 !default;
$brand-github: #000000 !default;
$brand-pinterest: #bd081c !default;
$brand-whatsapp: #25d366 !default;
$brand-behance: #1769ff !default;
$brand-dribbble: #ea4c89 !default;
$brand-youtube: #ff0000 !default;

$secondary-dark: #112b3c;

// theme colors
$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"dark": $dark,
);
$custom-colors: (
	"skyblue": $skyblue,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

$body-bg-color: #f7f7f8;
// dark theme color
$dark-light-color: #cad0d3;
$shark-color: #1e272e;
$space-color: #2b343b;
$spruce-color: #384148;
$limed-color: #374047;
$oslo_gray-color: #7d8993;

// Typography
$body-color: #4d4d4d !default;
$dark-color: #1c1c1c;
$gray-color: #fbfbfb;
$gray-color-1: #bfbfbf;
$gray-blue-color: #e5eaf0;

$font-family-sans-serif: "Rational Display Book", -apple-system,
	BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-family-secondary: "Rational Display", -apple-system, BlinkMacSystemFont,
	"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
	"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-weight-light: 300 !default;
// $font-weight-base: 400;
$headings-font-weight: 400 !default;

// Shadows
$box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-9, 0.2) !default;
$box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-9, 0.15) !default;
// $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// Borders Radius
$border-radius: 0.35rem !default;
$border-color: darken($gray-2, 2%) !default;

// Spacing Variables
// Change below variable if the height of the navbar changes
$topbar-base-height: 4.375rem !default;

// Card
$card-cap-bg: $white !default;
$card-border-width: 1px;
$card-border-radius: 8px;
$card-border-color: $gray-3;

// Adjust column spacing for symmetry
$spacer: 1rem !default;
$grid-gutter-width: $spacer * 1.5 !default;

// Transitions
$transition-collapse: height 0.15s ease !default;

// Dropdowns
$dropdown-font-size: 0.85rem !default;
$dropdown-border-color: $gray-3 !default;
$dropdown-border-width: 1px !default;

$dropdown-link-color: $gray-9;
$dropdown-link-hover-color: $dark-color;
$dropdown-link-hover-bg: $gray-2;
$dropdown-link-disabled-color: $gray-5;
$dropdown-item-padding-y: 0.6rem;
$dropdown-item-padding-x: 1rem;
$dropdown-header-color: $dark-color;
$dropdown-divider-bg: $gray-5 !default;

$tooltip-bg: $dark-color;

// List Group
$list-group-item-padding-y: 0.8rem;
$list-group-item-padding-x: 1rem;

// accordion color
$accordion-padding-y: 1.2rem;
$accordion-button-color: $dark-color;
$accordion-transition: all 0.3s ease-in-out;
$accordion-button-active-bg: $primary;
$accordion-button-active-color: $white;
$accordion-button-focus-box-shadow: none;
$accordion-icon-width: 1.15rem;

// scss-docs-start display-headings
$display-font-sizes: (
	1: 4.4rem,
	2: 4rem,
	3: 3.5rem,
	4: 3rem,
	5: 2.5rem,
	6: 2rem,
) !default;

$display-font-weight: 600 !default;

// Spacing
$spacer: 1rem !default;
$spacers: (
	0: 0,
	1: calc($spacer / 4),
	2: calc($spacer / 2),
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
	6: $spacer * 3.5,
	7: $spacer * 4,
	8: $spacer * 4.5,
) !default;

$position-values: (
	0: 0,
	20: 20%,
	50: 50%,
	100: 100%,
);

$alert-bg-scale: -80%;
$alert-border-scale: -70%;

// Modal
$modal-sm: 400px;
$modal-md: 700px;
$modal-lg: 900px;
$modal-xl: 1140px;
$modal-inner-padding-y: 1rem;
$modal-inner-padding-x: 1.5rem;
$modal-header-padding-y: 1rem;
$modal-footer-padding-y: 0.7rem;
$modal-content-border-radius: 20px;
$btn-close-color: $secondary;
$btn-close-width: 14px;

$offcanvas-padding-y: $modal-inner-padding-y;
$offcanvas-padding-x: $modal-inner-padding-x;

// Pagination
$pagination-hover-color: $white;
$pagination-hover-bg: $primary;
$pagination-active-color: $white;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;
$pagination-focus-color: $white;
$pagination-focus-bg: $primary;
$pagination-padding-y-lg: 0.45rem;
$pagination-padding-x-lg: 1rem;

// Nav
$nav-link-font-size: 14px;
$nav-link-font-weight: 700;
$nav-link-color: $dark-color;
$nav-link-hover-color: $primary;
$nav-link-disabled-color: $dark-400;
$nav-pills-border-radius: 6px;
$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $primary;

$popover-header-bg: $gray-3;
$popover-header-color: $dark-color;
$popover-arrow-color: $gray-3;

$toast-header-color: $dark-color;

$table-striped-bg-factor: 1;
$table-striped-bg: #f2f4f7;
$table-hover-bg-factor: 1;
$table-hover-bg: #f2f4f7;
