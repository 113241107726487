.custom-check-group {
	position: relative;
	.custom-check-item {
		&:not(:first-child) {
			margin-top: 12px;
		}
	}
	&.g-1 {
		.custom-check-item {
			&:not(:first-child) {
				margin-top: 4px;
			}
		}
	}
	&.g-2 {
		.custom-check-item {
			&:not(:first-child) {
				margin-top: 8px;
			}
		}
	}
	&--inline {
		display: inline-flex;
		flex-wrap: wrap;
		.custom-check-item {
			margin-right: 12px;
			margin-top: 0 !important;
			margin-bottom: 12px;
		}
		&.g-1 {
			.custom-check-item {
				margin-right: 4px;
				margin-top: 0px !important;
				margin-bottom: 4px;
			}
		}
		&.g-2 {
			.custom-check-item {
				margin-right: 8px;
				margin-top: 0px !important;
				margin-bottom: 8px;
			}
		}
	}
}
.custom-check-item {
	.btn-check {
		&:not(:checked) ~ .btn {
			// border-color: $input-border-color;
			// background: transparent;
			// color: $dark-700;
			// .check-icon {
			//     color: $input-border-color;
			// }
		}
		&:checked ~ .btn {
			.check-icon {
				.active-icon {
					opacity: 1;
				}
			}
		}
		&:checked {
			& ~ .btn-primary {
				color: $white;
				background: rgba($primary, 0.8);
				border-color: $primary;
			}
			& ~ .btn-secondary {
				background: rgba($secondary, 0.8);
				border-color: $secondary;
			}
			& ~ .btn-success {
				background: rgba($success, 0.8);
				border-color: $success;
			}
			& ~ .btn-danger {
				color: $white;
				background: rgba($danger, 0.8);
				border-color: $danger;
			}
			& ~ .btn-warning {
				background: rgba($warning, 0.8);
				border-color: $warning;
			}
			& ~ .btn-info {
				background: rgba($info, 0.8);
				border-color: $info;
			}
			& ~ .btn-dark {
				background: rgba($dark, 0.8);
				border-color: $dark;
			}
			& ~ .btn-light {
				background: rgba($light, 0.8);
				border-color: $dark;
			}
		}
	}
	> .btn {
		display: flex;
		align-items: center;
		width: 100%;
		text-align: left;
	}
	.check-icon {
		font-size: 24px;
		line-height: 1;
		i {
			display: block;
		}
		.material-icons {
			font-size: 26px;
		}
		.check-icon-box {
			position: relative;
		}
		.active-icon {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
		}
	}
}
