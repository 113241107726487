.h1 {
	font-size: 64px;
	color: $dark-color;
	font-weight: 600;
	font-family: $font-family-secondary;
}
.h1.large {
	font-size: 72px;
	font-weight: 600;
	font-family: $font-family-secondary;
	@include breakpoint(max, 1024) {
		font-size: 50px;
	}
	@include breakpoint(max, 767) {
		font-size: 40px;
	}
}
.h2 {
	font-size: 48px;
	color: $dark-color;
	font-weight: 600;
	font-family: $font-family-secondary;
	@include breakpoint(max, 1024) {
		font-size: 40px;
	}
	@include breakpoint(max, 767) {
		font-size: 34px;
	}
}
.h3 {
	font-size: 36px;
	line-height: 50px;
	color: $dark-color;
	font-weight: 500;
	font-family: $font-family-secondary;
	@include breakpoint(max, 767) {
		font-size: 30px;
	}
}
.h4 {
	font-size: 28px;
	line-height: 40px;
	color: $dark-color;
	font-weight: 500;
	font-family: $font-family-secondary;
	@include breakpoint(max, 767) {
		font-size: 22px;
	}
}
.h5 {
	font-size: 20px;
	line-height: 28px;
	color: $dark-color;
	font-weight: 500;
	font-family: $font-family-secondary;
}
.h6 {
	font-size: 18px;
	color: $dark-color;
	font-weight: 500;
	font-family: $font-family-secondary;
}

.text-small {
	font-size: 14px;
}
.text-ex-small {
	font-size: 12px;
}
.text-large {
	font-size: 18px;
	line-height: 30px;
}
.text-normal {
	font-size: 16px;
	line-height: 26px;
}
.text-ex-large {
	font-size: 20px;
}

.icon-normal {
	font-size: 24px;
	line-height: 1;
}
.icon-small {
	font-size: 20px;
	line-height: 1;
}
.icon-medium {
	font-size: 32px;
	line-height: 1;
}
.icon-large {
	font-size: 48px;
	line-height: 1;
}
.icon-ex-large {
	font-size: 72px;
	line-height: 1;
}
.fw-medium {
	font-weight: 500;
	font-family: $font-family-secondary;
}
.fw-bold {
	font-family: $font-family-secondary;
}

.title-line {
	padding-bottom: 20px;
	position: relative;
	&:after {
		content: "";
		width: 30px;
		height: 4px;
		@include border-radius(8px);
		position: absolute;
		left: 0;
		bottom: 5px;
		background-color: currentColor;
	}
}
