@import "../../../scss/variables";
@import "../../../scss/mixins";

.banner-wrap {
	padding: 175px 0;
	background-image: url(../../../assets/img/banner-bg.jpg);
	background-position: center center;
	background-size: cover;
	overflow: hidden;
	@include breakpoint(max, 767) {
		padding: 60px 0;
	}
	h1 {
		padding-bottom: 64px;
		@include breakpoint(max, 767) {
			padding-bottom: 32px;
		}
	}
	p {
		font-size: 24px;
		line-height: 40px;
		// max-width: 641px;
		color: $black;
		@include breakpoint(max, 767) {
			font-size: 20px;
			line-height: 32px;
		}
		strong {
			font-weight: 600;
			font-family: $font-family-secondary;
		}
	}
}

.typed-cursor {
	position: relative;
	z-index: -1;
}
