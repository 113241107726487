// spinner style
.spinner-border {
	width: 1.8rem;
	height: 1.8rem;
	border-width: 0.15rem;
}
.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.15rem;
}

// dropdown style
@keyframes slideIn {
	0% {
		opacity: 0;
		margin-top: 10px;
	}
	100% {
		opacity: 1;
		margin-top: 0;
	}
	0% {
		opacity: 0;
		margin-top: 10px;
	}
}
.dropdown-menu {
	-webkit-animation-name: slideIn;
	animation-name: slideIn;
	animation-duration: 0.3s;
	-webkit-animation-duration: 0.3s;
	animation-fill-mode: both;
	-webkit-animation-fill-mode: both;
	@include box-shadow(0px 0px 50px 0 rgba(82, 63, 105, 0.2));
	&.dropdown-menu-md {
		width: 240px;
		max-height: 300px;
		overflow: auto;
	}
}

.badge {
	font-weight: 500;
	padding: 0.45em 0.7em;
}

.alert {
	// color: $white;
	border-width: 2px;
	font-size: 15px;
	.alert-link {
		font-weight: 700;
		color: inherit;
		text-decoration: underline;
	}
	&.alert-outline {
		background: $white;
	}
	&.alert__icon {
		display: flex;
		align-items: center;
		.icon {
			flex-shrink: 0;
			margin-right: 20px;
			font-size: 24px;
			line-height: 1;
		}
	}
	.alert-heading {
		padding-bottom: 10px;
	}
	.btn-close {
		box-shadow: none;
	}
}

// accordion style
.accordion-button {
	font-weight: 700;
}

.carousel-control-prev,
.carousel-control-next {
	opacity: 1;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
	background-color: $primary;
	@include border-radius(100%);
	background-size: 22px 22px;
	width: 42px;
	height: 42px;
	opacity: 1;
	background-position: center center;
}

.dropdown-toggle {
	&:after {
		display: inline-flex;
		align-items: center;
		border: 0;
		width: 8px;
		height: 8px;
		border-right: 2px solid currentColor;
		border-bottom: 2px solid currentColor;
		transform: rotate(45deg);
		margin: 0 0 0px 5px;
		vertical-align: 7px;
	}
}
.dropup,
.dropend {
	.btn {
		&.btn-no-arrow {
			&:after {
				display: none;
			}
		}
	}
}
.dropup {
	.btn {
		&.btn-no-arrow {
			&:after {
				display: none;
			}
		}
	}
	.dropdown-toggle {
		&:after {
			display: inline-flex;
			align-items: center;
			border: 0;
			width: 8px;
			height: 8px;
			border-top: 2px solid currentColor;
			border-left: 2px solid currentColor;
			transform: rotate(45deg);
			margin: 0 0 0px 5px;
			vertical-align: 1px;
		}
	}
}

.dropend {
	.dropdown-toggle {
		&:after {
			display: inline-flex;
			align-items: center;
			border: 0;
			width: 8px;
			height: 8px;
			border-right: 2px solid currentColor;
			border-top: 2px solid currentColor;
			transform: rotate(45deg);
			margin: 0 0 0px 2px;
			vertical-align: 4px;
		}
	}
}
.dropstart {
	.btn {
		&.btn-no-arrow {
			&:before {
				display: none;
			}
		}
	}
	.dropdown-toggle {
		&:before {
			display: inline-flex;
			align-items: center;
			border: 0;
			width: 8px;
			height: 8px;
			border-left: 2px solid currentColor;
			border-bottom: 2px solid currentColor;
			transform: rotate(45deg);
			margin: 0 2px 0px 0;
			vertical-align: 5px;
		}
	}
}
.dropdown-item {
	font-size: 14px;
	@include transition(all 0.3s ease-in-out);
	&.active {
		font-weight: 700;
	}
}

.list-group-item {
	font-size: 14px;
}

// Modal
.modal-title {
	color: $dark-color;
}
.modal-header {
	padding: $modal-header-padding-y $modal-inner-padding-x;
	.btn-close {
		box-shadow: none;
		opacity: 1;
		border: 1px solid $secondary;
		@include border-radius(100%);
		padding: 13px;
		flex-shrink: 0;
		margin-left: 10px;
	}
}
.modal-body {
	padding: $modal-footer-padding-y $modal-inner-padding-x;
}
.modal-footer {
	padding: $modal-footer-padding-y $modal-inner-padding-x;
}

.offcanvas {
	border: 0;
	z-index: 1055;
}
.offcanvas-title {
	color: $dark-color;
}
.offcanvas-header {
	.btn-close {
		box-shadow: none;
		opacity: 1;
		border: 1px solid $secondary;
		@include border-radius(100%);
		padding: 8px;
		flex-shrink: 0;
		margin-left: 10px;
	}
}

.pagination {
	.page-link {
		font-size: 16px;
		line-height: 20px;
		box-shadow: none;
	}
	.page-item {
		&.active {
			.page-link {
				font-weight: 700;
			}
		}
	}
	&.pagination-lg {
		.page-link {
			font-size: 18px;
			line-height: 24px;
		}
	}
	&.pagination-box {
		.page-item {
			margin: 0 4px;
		}
		.page-link {
			width: 32px;
			height: 32px;
			padding: 0;
			margin-left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			@include border-radius(6px !important);
		}
		&.pagination-lg {
			.page-link {
				width: 40px;
				height: 40px;
			}
		}
	}
	&.pagination-circle {
		.page-item {
			margin: 0 4px;
		}
		.page-link {
			width: 32px;
			height: 32px;
			padding: 0;
			margin-left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			@include border-radius(100% !important);
		}
		&.pagination-lg {
			.page-link {
				width: 40px;
				height: 40px;
			}
		}
	}
}

// Navs
.nav {
	.nav-item {
		.nav-link {
			&.active {
				font-weight: 700;
				color: $primary !important;
			}
			&.disabled {
				color: #9e9e9e !important;
			}
		}
	}
	&.nav-icons {
		.nav-link {
			display: inline-flex;
			align-items: center;
			.nav-icon {
				font-size: 130%;
				margin-right: 8px;
			}
		}
	}
	&.nav-icons-circle {
		.nav-link {
			display: inline-flex;
			align-items: center;
			.nav-icon {
				width: 42px;
				height: 42px;
				@include border-radius(100%);
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 20px;
				margin-right: 12px;
				background: $primary;
				color: $white;
			}
		}
		&.nav-tabs {
			.nav-link {
				border-left: 0;
				border-right: 0;
				border-top: 0;
			}
		}
	}
	&.nav-tabs-vertical {
		border: 0;
		.nav-link {
			border: 0;
			width: 100%;
		}
		&.nav-active-border {
			border-bottom: 2px solid transparent;
			&.active {
				border-bottom-color: $primary;
			}
		}
	}
	&.nav-active-border {
		.nav-link {
			border-bottom: 2px solid transparent;
			&.active {
				border-bottom-color: $primary;
			}
		}
	}
	&.nav-tabs-border {
		border-bottom: 1px solid $gray-3;
		.nav-link {
			border-bottom: 2px solid transparent;
			margin-bottom: -1px;
			&.active {
				border-bottom-color: $primary;
			}
		}
	}
	&.nav-tabs {
		.nav-link {
			padding: 11px 24px;
			font-size: 14px;
			line-height: 20px;
		}
	}
	&.nav-pills {
		.nav-item {
			.nav-link {
				padding: 11px 24px;
				font-size: 14px;
				line-height: 20px;
				&.active {
					font-weight: 700;
					color: $white !important;
				}
			}
		}
		&.nav-pills-vertical {
			.nav-link {
				width: 100%;
				text-align: left;
			}
		}
	}
	&.nav-fill {
		text-align: center;
		.nav-link {
			justify-content: center;
		}
	}
	&.custom-nav {
		margin: 0 -8px;
		overflow: auto;
		flex-wrap: nowrap;
		padding-bottom: 4px;
		.nav-item {
			padding: 0 8px 8px;
		}
		&-primary {
			.nav-link {
				background: rgba($primary, 0.1);
				color: $primary !important;
				&.active,
				&:hover {
					background: $primary;
					color: $white !important;
				}
			}
		}
		&-secondary {
			.nav-link {
				background: rgba($secondary, 0.1);
				color: $secondary !important;
				&.active,
				&:hover {
					background: $secondary;
					color: $white !important;
				}
			}
		}
		&-success {
			.nav-link {
				background: rgba($success, 0.1);
				color: $success !important;
				&.active,
				&:hover {
					background: $success;
					color: $white !important;
				}
			}
		}
		&-info {
			.nav-link {
				background: rgba($info, 0.1);
				color: $info !important;
				&.active,
				&:hover {
					background: $info;
					color: $white !important;
				}
			}
		}
		&-danger {
			.nav-link {
				background: rgba($danger, 0.1);
				color: $danger !important;
				&.active,
				&:hover {
					background: $danger;
					color: $white !important;
				}
			}
		}
		&-warning {
			.nav-link {
				background: rgba($warning, 0.1);
				color: $warning !important;
				&.active,
				&:hover {
					background: $warning;
					color: $white !important;
				}
			}
		}
		&-light {
			.nav-link {
				background: rgba($light, 0.5);
				color: $gray-7 !important;
				&.active,
				&:hover {
					background: $light;
					color: $gray-7 !important;
				}
			}
		}
	}
}

.progress-bar {
	font-weight: 700;
}

.toast {
	width: 100%;
	max-width: 350px;
	&.custom-toast {
		border: 0;
		@include border-radius(10px);
	}
}

.collapsing {
	@include transition(height 0.3s ease-in-out);
}
