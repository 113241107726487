@font-face {
	font-family: "Rational Display Book";
	src: url("../assets/fonts/RationalDisplay-Book.woff2") format("woff2"),
		url("../assets/fonts/RationalDisplay-Book.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Rational Display Book";
	src: url("../assets/fonts/RationalDisplay-Bold.woff2") format("woff2"),
		url("../assets/fonts/RationalDisplay-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Rational Display";
	src: url("../assets/fonts/RationalDisplay-SemiBold.woff2") format("woff2"),
		url("../assets/fonts/RationalDisplay-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Rational Display";
	src: url("../assets/fonts/RationalDisplay-Medium.woff2") format("woff2"),
		url("../assets/fonts/RationalDisplay-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
