@import "../../scss/variables";
@import "../../scss/mixins";

.css-generator-list-wrap {
	position: relative;
	.css-generator-list {
		padding-top: 60px;
		> ul {
			> li {
				padding: 0px 0 36px;
				> h4 {
					padding-bottom: 24px;
				}
			}
		}
	}
}

.cm-generator-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
	li {
		width: 33.33%;
		padding: 0 10px 20px;
		a {
			display: flex;
			font-size: 16px;
			font-weight: 500;
			font-family: $font-family-secondary;
			color: $gray-11;
			background: $gray-3;
			border: 1px solid $gray-3;
			@include border-radius(6px);
			@include transition(all 0.3s ease-in-out);
			.txt {
				padding: 16px;
				width: 100%;
			}
			span {
				display: flex;
				align-items: center;
			}
			.arrow {
				width: 50px;
				margin-left: auto;
				flex-shrink: 0;
				justify-content: center;
				@include border-radius(0 6px 6px 0);
				@include transition(all 0.3s ease-in-out);
			}
			&:hover {
				color: $gray-12;
				background: rgba($primary, 0.2);
				border-color: $primary;
				@include box-shadow(0 10px 20px rgba($dark, 0.1));
				.arrow {
					background: $primary;
					color: $white;
				}
			}
		}
	}
}

.generator-sidebar {
	width: calc(100% - 16px);
	height: 100%;
	overflow: auto;
	// background-image: url(../../assets/img/bg-pattern.jpg);
	background-color: $white;
	background-position: center center;
	background-size: cover;
	border: 1px solid #f0e9fb;
	padding: 24px 12px;
	position: absolute;
	left: 16px;
	top: 0;
	@include box-shadow(0 32px 140px -16px rgba($black, 0.08));
	@include border-radius(8px);
	@include breakpoint(max, 767) {
		position: relative;
		width: 100%;
		left: 0;
		height: auto;
		max-height: 300px;
		margin-top: 12px;
	}
	.sidebar-title-item {
		font-size: 15px;
		font-weight: 600;
		font-family: $font-family-secondary;
		text-transform: uppercase;
		color: $black;
		padding: 12px 12px 0;
		margin-bottom: 12px;
	}
	.sidebar-list {
		> li {
			padding-bottom: 5px;
			> a {
				display: block;
				font-size: 16px;
				line-height: 18px;
				color: $gray-12;
				padding: 11px 20px;
				@include border-radius(6px);
				@include transition(all 0.3s ease-in-out);
				&:hover,
				&.active {
					background: $primary;
					color: $white;
				}
			}
		}
	}
}
