.card {
	&.card--custom {
		@include box-shadow(0 0px 24px rgba($black, 0.1));
		border: 1px solid $gray-6;
		@include border-radius(8px);
		.card-header {
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			flex-wrap: wrap;
			min-height: 60px;
			.card-title {
				display: flex;
				align-items: center;
				margin-right: 10px;
			}
		}
		.card-body {
			padding: 24px;
		}
	}
	&.card--stretch {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		height: 100%;
	}
	.card-header {
		padding: 5px 20px;
		.card-title {
			margin: 0;
			font-size: 16px;
			font-weight: 700;
			color: $dark-color;
			text-transform: capitalize;
		}
	}
}
