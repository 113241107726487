.cm-nav {
	padding: 8px 0;
	.cm-nav__item {
		margin: 0;
		display: block;
	}
	.cm-nav__link {
		display: flex;
		align-items: center;
		padding: 10px 20px;
		font-size: 16px;
		color: $gray-900;
		white-space: nowrap;
		background: transparent;
		border: 0;
		width: 100%;
		text-align: left;
		@include transition(all 0.3s ease-in-out);
		&:hover,
		&.is--active {
			color: $white !important;
			background: $secondary;
		}
		.cm-nav__icon {
			flex-shrink: 0;
			width: 35px;
			font-size: 20px;
			line-height: 1;
			* {
				font-size: 20px;
				display: block;
			}
		}
	}
	&.cm-nav--rounded & {
		&__link {
			@include border-radius(6px);
		}
	}
	&.cm-nav-primary {
		.cm-nav__link {
			&:hover,
			&.is--active {
				color: $white;
				background: $primary;
			}
		}
	}
	&.cm-nav-success {
		.cm-nav__link {
			&:hover,
			&.is--active {
				color: $white;
				background: $success;
			}
		}
	}
	&.cm-nav-info {
		.cm-nav__link {
			&:hover,
			&.is--active {
				color: $white;
				background: $info;
			}
		}
	}
	&.cm-nav-warning {
		.cm-nav__link {
			&:hover,
			&.is--active {
				color: $white;
				background: $warning;
			}
		}
	}
	&.cm-nav-danger {
		.cm-nav__link {
			&:hover,
			&.is--active {
				color: $white;
				background: $danger;
			}
		}
	}
	&.cm-nav-light {
		.cm-nav__link {
			&:hover,
			&.is--active {
				color: $gray-7;
				background: $gray-2;
			}
		}
	}
}
