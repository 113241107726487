//colors
$color_mercury_approx: #e6e6e6;
$black_40: rgba(0, 0, 0, 0.4);
$color_silver_approx: #ccc;
$color_sushi_approx: $primary;
$color_alto_approx: $dark;

.rangeslider {
	margin: 20px 0;
	position: relative;
	background: $color_mercury_approx;
	touch-action: none;
	display: block;
	.rangeslider__fill {
		display: block;
	}
	.rangeslider__handle {
		background: rgba($primary, 0.7);
		border: 1px solid $primary;
		cursor: pointer;
		display: inline-block;
		position: absolute;
		.rangeslider__active {
			opacity: 1;
		}
	}
	.rangeslider__handle-tooltip {
		width: 40px;
		height: 40px;
		text-align: center;
		position: absolute;
		background-color: $dark;
		font-weight: normal;
		font-size: 14px;
		transition: all 100ms ease-in;
		border-radius: 40px;
		display: inline-block;
		color: $white;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		span {
			margin-top: 13px;
			display: inline-block;
			line-height: 100%;
		}
		&:after {
			content: " ";
			position: absolute;
			width: 0;
			height: 0;
		}
	}
}
.rangeslider-horizontal {
	height: 8px;
	border-radius: 10px;
	.rangeslider__fill {
		height: 100%;
		background-color: $color_sushi_approx;
		border-radius: 10px;
		top: 0;
	}
	.rangeslider__handle {
		width: 30px;
		height: 30px;
		border-radius: 30px;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		&:after {
			content: " ";
			position: absolute;
			width: 16px;
			height: 16px;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			border-radius: 50%;
			background-color: $white;
			border: 1px solid $primary;
		}
	}
	.rangeslider__handle-tooltip {
		top: -55px;
		&:after {
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid $dark;
			left: 50%;
			bottom: -7px;
			transform: translate3d(-50%, 0, 0);
		}
	}
}
.rangeslider-vertical {
	margin: 20px auto;
	height: 150px;
	max-width: 10px;
	background-color: transparent;
	.rangeslider__fill {
		position: absolute;
		width: 100%;
		background-color: $color_sushi_approx;
		box-shadow: none;
		bottom: 0;
	}
	.rangeslider__handle {
		position: absolute;
		width: 30px;
		height: 10px;
		left: -10px;
		box-shadow: none;
	}
	.rangeslider__handle-tooltip {
		left: -100%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		&:after {
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			border-left: 8px solid $dark;
			left: 100%;
			top: 12px;
		}
	}
	.rangeslider__labels {
		position: relative;
		list-style-type: none;
		margin: 0 0 0 24px;
		padding: 0;
		text-align: left;
		width: 250px;
		height: 100%;
		left: 10px;
		.rangeslider__label-item {
			position: absolute;
			transform: translate3d(0, -50%, 0);
		}
		.rangeslider__label-item::before {
			content: "";
			width: 10px;
			height: 2px;
			background: $black;
			position: absolute;
			left: -14px;
			top: 50%;
			transform: translateY(-50%);
			z-index: -1;
		}
	}
}
.rangeslider-reverse {
	&.rangeslider-horizontal .rangeslider__fill {
		right: 0;
	}
	&.rangeslider-vertical .rangeslider__fill {
		top: 0;
		bottom: inherit;
	}
}
.rangeslider__labels {
	position: relative;
	.rangeslider__label-item {
		position: absolute;
		font-size: 14px;
		cursor: pointer;
		display: inline-block;
		top: 10px;
		transform: translate3d(-50%, 0, 0);
	}
}
