.cm-header-wrap {
	position: relative;
	height: 90px;
	.cm-header {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 90px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		background: $white;
		z-index: 123;
		@include transition(all 0.3s ease-in-out);
		.cm-logo {
			a {
				display: inline-block;
			}
		}
	}
	&.shrink {
		.cm-header {
			@include box-shadow(0px 0px 30px rgba($dark, 0.1));
		}
	}
	.sidebar-toggle {
		width: 30px;
		height: 30px;
		z-index: 1;
		border: 0;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.cm-menu-wrap {
	@include breakpoint(max, 767) {
		position: fixed;
		width: 100%;
		height: 100vh;
		right: -100%;
		top: 0;
		background: rgba($white, 0.6);
		overflow: auto;
		z-index: 123;
		backdrop-filter: blur(14px);
		@include transition(all 0.3s ease-in-out);
		&.menu-show {
			right: 0;
		}
	}
	.sidebar-toggle {
		position: absolute;
		right: 8px;
		top: 8px;
	}
}

.cm-menu {
	display: flex;
	align-items: center;
	@include breakpoint(max, 767) {
		flex-wrap: wrap;
		padding: 48px 24px;
		overflow: hidden;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: -50px;
			top: -50px;
			width: 200px;
			height: 200px;
			border: 30px solid rgba($primary, 0.25);
			@include border-radius(100%);
		}
		&:after {
			content: "";
			position: absolute;
			right: -20%;
			top: -20%;
			width: 400px;
			height: 400px;
			border: 40px solid rgba($success, 0.2);
			@include border-radius(100%);
		}
	}
	&__item {
		margin-left: 10px;
		@include breakpoint(max, 767) {
			width: 100%;
			margin-left: 0;
			margin-bottom: 16px;
			position: relative;
			z-index: 1;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__icon {
		svg {
			display: block;
			width: 30px;
			height: 30px;
		}
	}
	&__txt {
		font-size: 15px;
		line-height: 24px;
		font-weight: 500;
		font-family: $font-family-secondary;
		color: currentColor;
		padding-left: 12px;
		@include breakpoint(max, 767) {
			font-size: 15px;
			color: currentColor;
			padding-left: 12px;
		}
	}
	&__link {
		display: flex;
		align-items: center;
		padding: 9px 12px;
		@include border-radius(45px);
		@include transition(all 0.3s ease-in-out);
		@include breakpoint(max, 767) {
			max-width: 320px;
			margin: 0 auto;
			padding: 9px 12px;
		}
		&.active {
			padding: 9px 12px;
			.cm-menu__txt {
				font-size: 15px;
				padding-left: 12px;
			}
		}
		@include breakpoint(min, 768) {
			&:hover {
				padding: 9px 12px;
				.cm-menu__txt {
					font-size: 15px;
					padding-left: 12px;
				}
			}
		}
		&--success {
			color: $success;
			&:hover,
			&.active {
				background: $success;
				color: $white;
			}
		}
		&--primary {
			color: $primary;
			&:hover,
			&.active {
				background: $primary;
				color: $white;
			}
		}
		&--secondary {
			color: $secondary;
			&:hover,
			&.active {
				background: $secondary;
				color: $white;
			}
		}
		&--danger {
			color: $danger;
			&:hover,
			&.active {
				background: $danger;
				color: $white;
			}
		}
		&--dark {
			color: $dark;
			&:hover,
			&.active {
				background: $dark;
				color: $white;
			}
		}
		&--info {
			color: $info;
			&:hover,
			&.active {
				background: $info;
				color: $white;
			}
		}
	}
}
