.datepicker {
	font-family: inherit;
	font-size: 16px;
	width: 300px;
	@include border-radius(8px);
	@include breakpoint(max, 767) {
		width: 270px;
		font-size: 14px;
	}
	* {
		@include transition(all 0.3s ease-in-out);
	}
	&--day-name {
		font-weight: 700;
		color: $primary;
		margin: 1px;
	}
	&--nav-title {
		font-size: 14px;
		font-weight: 700;
		color: $dark-800;
		i {
			color: $dark-600;
		}
		&:hover {
			background: rgba($primary, 0.1);
			color: $primary;
			i {
				color: $dark;
			}
		}
	}
	&--cell {
		height: 40px;
		margin: 1px;
		&.-focus- {
			background: rgba($primary, 0.1);
			color: $primary;
		}
		&.-current- {
			font-weight: 700;
			color: $primary;
			&.-focus- {
				background: $primary;
				color: $white;
			}
		}
		&.-selected- {
			font-weight: 700;
			color: $white;
			background: $primary;
			&.-focus- {
				background: $primary;
				color: $white;
			}
		}
		&.-in-range- {
			background: rgba($primary, 0.1);
			color: $primary;
			border-radius: 0;
			&.-focus- {
				background-color: $primary;
				color: $white;
			}
		}
		&-day {
			width: calc(14.28571% - 2px);
		}
		&-month {
			width: calc(33.33% - 2px);
		}
		&-year {
			width: calc(25% - 2px);
		}
	}
	&--nav-action {
		path {
			stroke: $primary;
		}
		&:hover {
			background: rgba($primary, 0.1);
		}
	}
}

.datepicker-inline {
	.datepicker {
		width: 100%;
		border: 0;
		border-radius: 0;
	}
}

// flatpickr
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
	display: block;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
	font-size: 16px;
	font-weight: 400;
}
span.flatpickr-weekday {
	color: $primary;
	font-weight: 400;
	font-size: 14px;
}
.flatpickr-day {
	color: $dark;
	line-height: 37px;
	font-size: 15px;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
	fill: $primary;
}
.flatpickr-day.today {
	border-color: $primary;
	color: $primary;
	font-weight: 700;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange {
	background: rgba($primary, 0.1);
	border-color: rgba($primary, 0.1);
	color: $primary;
}
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
	background: rgba($primary, 0.1);
	border-color: $primary;
	color: $primary;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
	background: rgba($primary, 0.1);
	border-color: $primary;
	color: $primary;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
	background: rgba($primary, 0.1);
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
	background: $primary;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
	color: $dark-300;
}
.flatpickr-day.inRange {
	@include box-shadow(
		-5px 0 0 rgba($primary, 0.1),
		5px 0 0 rgba($primary, 0.1)
	);
}

.flatpickr-calendar-inline {
	.flatpickr-calendar {
		margin: 0 auto;
	}
}
.flatpickr-monthSelect-months {
	margin: 10px 1px 3px 1px;
	flex-wrap: wrap;
}

.flatpickr-monthSelect-month {
	background: none;
	border: 0;
	border-radius: 4px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: $dark-700;
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	font-size: 16px;
	margin: 0.5px;
	justify-content: center;
	padding: 10px;
	position: relative;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	text-align: center;
	width: 33%;
}

.flatpickr-monthSelect-month.disabled {
	color: $dark-300;
}

.flatpickr-monthSelect-month.disabled:hover,
.flatpickr-monthSelect-month.disabled:focus {
	cursor: not-allowed;
	background: none !important;
}

.flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-month:focus {
	background: rgba($primary, 0.1);
	cursor: pointer;
	outline: 0;
}

.flatpickr-monthSelect-month.selected {
	background-color: $primary;
	color: #fff;
	font-weight: 700;
}

// DateTimePicker
.dtpicker-overlay {
	background: rgba($dark, 0.5);
	.dtpicker-bg {
		font-family: $font-family-sans-serif;
	}
	.dtpicker-content {
		@include border-radius(10px);
	}
	.dtpicker-header {
		.dtpicker-title {
			font-size: 18px;
			color: $dark-800;
			font-weight: 700;
		}
		.dtpicker-value {
			font-size: 16px;
			color: $primary;
		}
		.dtpicker-close {
			width: 20px;
			height: 20px;
			padding: 0;
			font-size: 0;
			color: $dark-400;
			right: 10px;
			top: 0;
			&:before {
				content: "";
				width: 100%;
				height: 2px;
				background: currentColor;
				position: absolute;
				left: 0;
				top: 9px;
				@include transform-rotate(45deg);
				@include border-radius(5px);
			}
			&:after {
				content: "";
				width: 100%;
				height: 2px;
				background: currentColor;
				position: absolute;
				top: 9px;
				left: 0;
				@include transform-rotate(-45deg);
				@include border-radius(5px);
			}
		}
	}
	.dtpicker-components {
		.dtpicker-compButton {
			color: $dark-500;
			font-size: 20px;
			line-height: 1;
			height: auto;
			i {
				display: block;
			}
		}
		.dtpicker-compValue {
			color: $primary;
			font-size: 16px;
			margin: 10px 0;
		}
	}
	.dtpicker-buttonCont {
		.dtpicker-button {
			font-size: 15px;
			font-weight: 700;
			background: $primary;
			@include border-radius(5px);
			&.dtpicker-buttonClear {
				background: $light;
				color: $dark-800;
			}
		}
	}
}

.daterangepicker {
	border-color: $dark-100;
	color: $dark;
	@include box-shadow(0px 0px 30px 0px rgba($dark-800, 0.12));
	@include breakpoint(max, 564) {
		width: 300px;
	}
	&:before,
	&:after {
		display: none;
	}
	.drp-calendar {
		max-width: 310px;
		&.single {
			min-width: 290px;
		}
	}
	.ranges {
		li {
			font-size: 14px;
			color: $dark;
			&:hover {
				background: rgba($primary, 0.1);
			}
			&.active {
				background: $primary;
				font-weight: 700;
			}
		}
	}
	.calendar-table {
		th,
		td {
			height: 36px;
			width: 35px;
			min-width: 35px;
			font-size: 14px;
		}
	}
	th {
		color: $primary;
		&.month,
		&.week {
			color: $dark-800;
			select {
				height: 30px;
				background: $white;
				border: 1px solid $input-border-color;
				padding: 0 8px;
				font-size: 14px;
				@include border-radius(5px);
			}
		}
		&.available {
			&:hover {
				background: rgba($primary, 0.1);
				color: $dark;
			}
		}
	}
	td {
		&.available {
			&:hover {
				background: rgba($primary, 0.1);
				color: $dark;
			}
		}
		&.active,
		&.active:hover {
			background: $primary;
			color: $white;
			font-weight: 700;
		}
		&.in-range {
			background: rgba($primary, 0.1);
			color: $primary;
			font-weight: 700;
		}
	}
	td.off,
	td.off.in-range,
	td.off.start-date,
	td.off.end-date {
		color: $dark-300;
	}
	.calendar-time {
		select {
			width: auto;
			min-width: 50px;
			background: $white;
			border: 1px solid $input-border-color;
			padding: 0 8px;
			height: 30px;
			font-size: 14px;
			@include border-radius(5px);
		}
	}
	.drp-buttons {
		.drp-selected {
			font-size: 14px;
		}
		.btn + .btn {
			margin-left: 10px;
		}
	}
}
