.footer-wrap {
	position: relative;
	padding-bottom: 24px;
	padding-top: 24px;
	color: $black;
	@include breakpoint(max, 767) {
		font-size: 14px;
	}
	a {
		color: $primary;
		font-weight: 500;
		font-family: $font-family-secondary;
	}
}
