.table {
	font-size: 15px;
	color: currentColor;
	> :not(:first-child) {
		border-top: 0;
	}
	thead {
		th {
			color: $dark;
		}
	}
	&.table-bordered {
		> :not(caption) > * > * {
			padding: 0.5rem 1rem;
		}
	}
	&.table-lg {
		th,
		td {
			padding: 0.5rem 0.5rem;
		}
	}
	&.table-xl {
		th,
		td {
			padding: 0.9rem 0.5rem;
		}
	}
}

.custom-table {
	border-collapse: separate;
	border-spacing: 0 15px;
	&.dataTable {
		border-spacing: 0 15px;
	}
	td,
	th {
		padding: 12px 16px;
	}
	tbody {
		tr {
			@include box-shadow(0 0 5px rgba($black, 0.1));
			@include border-radius(6px);
			td {
				&.child {
					@include border-radius(6px);
				}
				&:first-child {
					@include border-radius(6px 0 0 6px);
				}
				&:last-child {
					@include border-radius(0 6px 6px 0);
				}
			}
		}
		td {
			background: $white;
		}
	}
}

.table-actions {
	display: flex;
	.btn {
		flex-shrink: 0;
		&:not(:last-child) {
			margin-right: 8px;
		}
	}
}
