@import "./checkbox";
@import "./custom-check";
@import "./rc-input";
@import "./rangeslider";

.form-wrap {
	.form-group {
		padding-bottom: 20px;
	}
	.input-group {
		margin-bottom: 20px;
		&.input-group-spinner {
			.form-control {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
		.form-control {
			width: 1%;
		}
		.btn {
			padding-top: 3px;
			padding-bottom: 3px;
			i {
				font-size: 20px;
				display: block;
			}
		}
	}
	.form-floating {
		> .form-control {
			@include placeholder {
				color: transparent;
			}
		}
		> .form-control:focus,
		> .form-control:not(:placeholder-shown),
		> .form-select {
			~ label {
				opacity: 1;
				font-size: 14px;
				color: $dark-500;
				height: 52%;
			}
		}
		> textarea.form-control:focus,
		> textarea.form-control:not(:placeholder-shown) {
			~ label {
				padding-top: 2px;
			}
		}
		> textarea.form-control ~ label {
			align-items: flex-start;
			padding-top: 10px;
		}
		> label {
			padding: 8px 20px;
			font-size: 15px;
			color: $input-placeholder-color;
			display: flex;
			align-items: center;
			@include transition(all 0.3s ease-in-out);
		}
		&.form-floating-custom {
			> .form-control:focus,
			> .form-control:not(:placeholder-shown),
			> .form-select {
				padding-top: 0;
				~ label {
					background: $white;
					padding: 0 5px;
					opacity: 1;
					font-size: 14px;
					color: $dark-500;
					height: 20px;
					top: -10px;
					left: 14px;
					margin-bottom: 0;
					max-width: 90%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					display: block;
					line-height: 1;
				}
			}
			> textarea.form-control:focus,
			> textarea.form-control:not(:placeholder-shown) {
				padding-top: 14px;
			}
		}
	}
	.form-label {
		font-size: 15px;
		color: $input-label-color;
		margin-bottom: 4px;
		font-weight: 500;
		font-family: $font-family-secondary;
		&__help {
			font-size: 12px;
			color: rgba($input-label-color, 0.6);
		}
	}
	.form-control {
		height: 52px;
		width: 100%;
		padding: 0px 20px;
		border: 1px solid $input-border-color;
		font-size: 16px;
		color: $dark;
		@include border-radius(6px);
		@include transition(all 0.3s ease-in-out);
		@include placeholder {
			color: $input-placeholder-color;
		}
		&[type="password"] {
			letter-spacing: 5px;
			@include placeholder {
				letter-spacing: 0;
			}
		}
		&:hover {
			border-color: $input-border-hover-color;
		}
		&:focus {
			border: 1px solid $primary !important;
			box-shadow: none;
			// @include box-shadow(inset 0 0 0 1px $primary);
		}
		&.form-control-sm {
			height: 42px;
			font-size: 15px;
			line-height: 20px;
			&[type="file"] {
				padding: 4px 8px;
				line-height: 26px;
			}
			&.tagify {
				padding-top: 0px;
				height: auto;
				min-height: 36px;
				font-size: 15px;
				line-height: 1.2;
			}
		}
		&.form-control-flate {
			border-radius: 0;
		}
		&.form-control-solid {
			background-color: #e9ecef;
			border-color: #e9ecef;
			&:hover {
				border-color: $input-border-hover-color;
			}
			&:focus {
				border: 1px solid $primary;
			}
		}
		&.form-control-rounded {
			@include border-radius(48px);
		}
		&.invalid {
			border: 1px solid $red;
			@include box-shadow(inset 0 0 0 1px $red);
		}
		&[type="color"] {
			padding-top: 14px;
			padding-bottom: 14px;
		}
		&[type="range"] {
			appearance: auto;
		}
		&[type="file"] {
			padding: 6px 10px;
			line-height: 38px;
		}
		&.flatpickr-input[readonly],
		&.flatpickr[readonly],
		&.datetimepicker-input[readonly] {
			background: $white;
		}
		&.tagify {
			padding-top: 4px;
			height: auto;
			min-height: 52px;
		}
	}
	.form-select {
		height: 52px;
		width: 100%;
		padding: 0px 30px 0 20px;
		border: 1px solid $input-border-color;
		font-size: 16px;
		color: $dark;
		box-shadow: none;
		@include border-radius(6px);
		@include transition(all 0.3s ease-in-out);
		&:hover {
			border-color: $input-border-hover-color;
		}
		&-sm {
			height: 36px;
		}
	}
	textarea {
		&.form-control {
			height: 160px;
			padding-top: 15px;
			&.autosize-textarea {
				height: auto;
				resize: vertical;
				min-height: 160px;
			}
			&.form-control-sm {
				height: 100px;
				&.autosize-textarea {
					min-height: 100px;
				}
			}
			&.form-control-rounded {
				@include border-radius(20px);
			}
		}
	}
	select {
		&.form-control {
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.7952 3.35258L7.00067 9.14854L1.20611 3.35188C0.929841 3.07562 0.482943 3.07562 0.206677 3.35188C-0.0688922 3.62815 -0.0688922 4.07575 0.206677 4.35201L6.50063 10.6481C6.7762 10.9243 7.2238 10.9243 7.49937 10.6481L13.7933 4.35207C14.0689 4.0758 14.0689 3.62751 13.7933 3.35124C13.5185 3.07631 13.0708 3.07631 12.7952 3.35258Z' fill='%235A5C69'/%3E%3C/svg%3E%0A");
			background-position: center right 15px;
			background-repeat: no-repeat;
			&[multiple] {
				background-image: none;
			}
		}
	}
	.form-text {
		font-size: 15px;
		font-weight: 400;
		color: $gray-900;
		a {
			color: $primary;
			text-decoration: underline;
			display: inline;
		}
	}
	.form-action {
		padding-top: 15px;
	}
	.form-invalid-feedback {
		color: $red;
		font-size: 14px;
		padding: 10px 0 10px 0;
	}
	.input-group__item {
		position: relative;
		&.prepend {
			.form-control {
				padding-left: 60px;
			}
			.prepend__item {
				position: absolute;
				left: 0;
				top: 0;
				width: 54px;
				height: 54px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
				border: 0;
				background: transparent;
				color: $gray-800;
				svg {
					width: 18px;
					height: 18px;
				}
			}
		}
	}

	.input-icon-box {
		position: relative;
		.form-control {
			padding-left: 50px;
			&:focus {
				~ .input-icon {
					color: $primary !important;
				}
			}
		}
		textarea.form-control {
			& ~ .input-icon {
				padding-top: 5px;
			}
		}
		.input-icon {
			position: absolute;
			left: 0;
			top: 0;
			width: 45px;
			height: 52px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 24px;
			line-height: 1;
			color: $input-placeholder-color;
			@include transition(all 0.3s ease-in-out);
			i {
				display: block;
				line-height: 1;
			}
		}
		&--right {
			.form-control {
				padding-left: 20px;
				padding-right: 50px;
			}
			.input-icon {
				right: 0;
				left: inherit;
			}
		}
	}

	.btn-color {
		border: 1px solid $input-border-color;
		span {
			display: block;
		}
		&.btn-sm {
			padding: 8px 14px;
			span {
				height: 24px;
				@include border-radius(4px);
			}
		}
	}

	.select__control {
		padding: 0;
		min-height: 42px;
		border: 1px solid $input-border-color;
		box-shadow: none !important;
		@include border-radius(5px);
		&:hover,
		&:focus {
			border-color: $primary !important;
		}
		&.select__control--is--focused {
			border-color: $primary !important;
			&:hover {
				border-color: $primary !important;
			}
		}
		.select__value-container {
			padding: 5px 12px;
		}
		.select__indicator-separator {
			display: none;
		}
		.select__placeholder {
			color: $input-placeholder-color;
			font-size: 15px;
			line-height: 1.3;
		}
		.select__single-value {
			font-size: 15px;
			line-height: 1.3;
			color: $dark;
		}
		.select__multi-value {
			@include border-radius(6px);
		}
		.select__multi-value__label {
			font-size: 14px;
			line-height: 1.4;
			font-weight: 500;
		}
		.select__multi-value__remove {
			@include border-radius(0 6px 6px 0);
		}
	}
}

.or-line {
	position: relative;
	text-align: center;
	font-size: 14px;
	color: $gray-700;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&:before {
		content: "";
		height: 1px;
		background: $input-border-color;
		width: 100%;
		opacity: 0.5;
		display: block;
	}
	&:after {
		content: "";
		height: 1px;
		background: $input-border-color;
		width: 100%;
		opacity: 0.5;
		display: block;
	}
	span {
		flex-shrink: 0;
		display: block;
		position: relative;
		z-index: 1;
		padding: 0px 10px 2px;
	}
}

[data-multiselectsplitter-wrapper-selector] {
	.form-control {
		background-image: none !important;
		height: auto;
		padding-top: 10px;
		max-height: 200px;
		option {
			padding: 2px 10px;
			@include border-radius(2px);
		}
	}
}

.toastr-success {
	background-color: $success;
}
.toastr-error {
	background-color: $danger;
}
.toastr-info {
	background-color: $info;
}
.toastr-warning {
	background-color: $warning;
}

.select__menu {
	.select__menu-list {
		max-height: 300px;
	}
	.select__group-heading {
		font-size: 14px;
		font-weight: 500;
		color: $black;
		font-family: $font-family-secondary;
	}
	.select__option {
		font-size: 16px;
		line-height: 1.6;
		padding: 8px 20px;
		@include transition(all 0.3s ease-in-out);
		color: $dark;
		cursor: pointer;
		&.select__option--is-selected {
			background: $primary;
			color: $white;
			&.select__option--is-focused {
				background: $primary;
			}
		}
		&.select__option--is-focused {
			background: rgba($primary, 0.15);
		}
	}
}
