@import "../../scss/variables";
@import "../../scss/mixins";

.inner-banner-wrap {
	padding: 90px 0;
	background-image: url(../../assets/img/banner-bg.jpg);
	background-position: center center;
	background-size: cover;
	overflow: hidden;
}
