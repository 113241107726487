.rc-input-number {
	display: block;
	height: 42px;
	margin: 0;
	padding: 0;
	font-size: 15px;
	line-height: 20px;
	color: $dark;
	border: 1px solid $input-border-color;
	@include border-radius(6px);
	@include transition(all 0.3s ease-in-out);
	&-focused {
		border-color: $primary;
	}
	&-out-of-range {
		input {
			color: red;
		}
	}

	&-handler {
		display: block;
		height: 12px;
		overflow: hidden;
		line-height: 12px;
		text-align: center;
		touch-action: none;

		&-active {
			background: #ddd;
		}
	}

	&-handler-up-inner,
	&-handler-down-inner {
		color: $dark;
		-webkit-user-select: none;
		user-select: none;
	}

	&:hover {
		border-color: $primary;

		.rc-input-number-handler-up,
		.rc-input-number-handler-wrap {
			border-color: $primary;
		}
	}

	&-disabled:hover {
		border-color: #d9d9d9;

		.rc-input-number-handler-up,
		.rc-input-number-handler-wrap {
			border-color: #d9d9d9;
		}
	}

	&-input-wrap {
		height: 100%;
		overflow: hidden;
	}

	&-input {
		width: 100%;
		height: 100%;
		padding: 0;
		color: $dark;
		line-height: 20px;
		text-align: center;
		border: 0;
		outline: 0;
		transition: all 0.3s ease;
		transition: all 0.3s;
		-moz-appearance: textfield;
		@include border-radius(6px);
	}

	&-handler-wrap {
		float: right;
		width: 24px;
		height: 100%;
		border-left: 1px solid $input-border-color;
		transition: all 0.3s;
		display: flex;
		flex-direction: column;
	}

	&-handler-up {
		padding-top: 1px;
		border-bottom: 1px solid $input-border-color;
		transition: all 0.3s;
		height: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		&-inner {
			&:after {
				content: "";
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-chevron-up' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'%3E%3C/path%3E%3Cpolyline points='6 15 12 9 18 15'%3E%3C/polyline%3E%3C/svg%3E");
				width: 16px;
				height: 16px;
				display: block;
				background-size: 16px 16px;
			}
		}
	}

	&-handler-down {
		transition: all 0.3s;
		height: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		&-inner {
			&:after {
				content: "";
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-chevron-down' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'%3E%3C/path%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
				width: 16px;
				height: 16px;
				display: block;
				background-size: 16px 16px;
			}
		}
	}

	@mixin handler-disabled() {
		opacity: 0.3;
		&:hover {
			color: #999;
			border-color: #d9d9d9;
		}
	}

	&-handler-down-disabled,
	&-handler-up-disabled {
		@include handler-disabled();
	}

	&-disabled {
		.rc-input-number-input {
			background-color: #f3f3f3;
			cursor: not-allowed;
			opacity: 0.72;
		}
		.rc-input-number-handler {
			@include handler-disabled();
		}
	}
}
