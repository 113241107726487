@import "../../scss/variables";
@import "../../scss/mixins";

.browser-list {
	ul {
		display: flex;
		li {
			margin-right: 10px;
			svg {
				display: block;
			}
		}
	}
}
