@import "../../../scss/variables";
@import "../../../scss/mixins";

.feature-section-wrap {
	padding: 60px 0 24px;
	@include breakpoint(max, 767) {
		padding: 48px 0 24px;
	}
}

.feature-list {
	&__item {
		margin-bottom: 20px;
	}
}

.feature-box {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	@include border-radius(24px);
	&__content {
		width: 406px;
		padding: 24px 35px 24px 48px;
		@include breakpoint(max, 991) {
			width: 100%;
		}
		@include breakpoint(max, 767) {
			padding: 24px;
		}
	}
	&__img {
		width: calc(100% - 406px);
		@include breakpoint(max, 991) {
			width: 100%;
		}
		img {
			display: block;
			margin: 0 auto;
			@include border-radius(0 24px 24px 0);
		}
	}
	&__icon {
		padding-bottom: 24px;
		.icon-box {
			width: 100px;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include border-radius(100%);
			color: $white;
			@include breakpoint(max, 767) {
				width: 80px;
				height: 80px;
			}
			svg {
				display: block;
				@include breakpoint(max, 767) {
					width: 48px;
					height: 48px;
				}
			}
		}
	}
	&__title {
		padding-bottom: 24px;
		@include breakpoint(max, 767) {
			padding-bottom: 16px;
		}
	}
	&__txt {
		color: $secondary-dark;
		padding-bottom: 24px;
	}
	&--vertical {
		flex-direction: column;
		&.reverse-column {
			flex-direction: column-reverse;
			@include breakpoint(max, 991) {
				flex-direction: column;
			}
			.feature-box__content {
				padding-top: 24px;
				padding-bottom: 48px;
				@include breakpoint(max, 991) {
					padding-top: 24px;
					padding-bottom: 24px;
				}
				@include breakpoint(max, 767) {
					padding: 24px;
				}
			}
			.feature-box__img {
				@include border-radius(24px 24px 0 0);
			}
		}
	}
	&--vertical & {
		&__content {
			width: 100%;
			padding: 48px 48px 24px;
			display: flex;
			@include breakpoint(max, 991) {
				display: block;
			}
			@include breakpoint(max, 767) {
				padding: 24px;
			}
			&-text {
				width: 100%;
				padding-left: 24px;
				@include breakpoint(max, 991) {
					padding-left: 0;
				}
			}
		}
		&__icon {
			width: 100px;
			flex-shrink: 0;
			padding-bottom: 0;
			@include breakpoint(max, 991) {
				padding-bottom: 24px;
			}
		}
		&__title {
			padding-bottom: 16px;
			@include breakpoint(max, 991) {
				padding-bottom: 24px;
			}
			@include breakpoint(max, 767) {
				padding-bottom: 16px;
			}
		}
		&__txt {
			padding-bottom: 16px;
			@include breakpoint(max, 991) {
				padding-bottom: 24px;
			}
		}
		&__img {
			width: 100%;
			@include border-radius(0 0 24px 24px);
		}
	}
	&--success {
		background: rgba($success, 0.1);
		.feature-box__icon {
			.icon-box {
				background: $success;
			}
		}
	}
	&--primary {
		background: rgba($primary, 0.1);
		.feature-box__icon {
			.icon-box {
				background: $primary;
			}
		}
	}
	&--secondary {
		background: rgba($secondary, 0.1);
		.feature-box__icon {
			.icon-box {
				background: $secondary;
			}
		}
	}
	&--dark {
		background: rgba($dark, 0.1);
		.feature-box__icon {
			.icon-box {
				background: $dark;
			}
		}
	}
	&--danger {
		background: rgba($danger, 0.1);
		.feature-box__icon {
			.icon-box {
				background: $danger;
			}
		}
	}
	&--info {
		background: rgba($info, 0.1);
		.feature-box__icon {
			.icon-box {
				background: $info;
			}
		}
	}
}
